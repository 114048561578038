import React, { useEffect } from "react";
import { Layout, Responsive, WidthProvider } from "react-grid-layout";
//import { getDashboardMain } from "../../api/dashboard";
import { Grid } from "@mui/material";
import useWidgets from "./useWidgets";
import { NODE_ENVIRONMENTS } from "../../constants";
//import { getJson } from "../../utils/http";

const ResponsiveGridLayout = WidthProvider(Responsive);
import { isNodeEnv } from "../../utils/env";

function Default(): React.ReactElement {
  const {
    layout,
    responsiveLayout,
    onLayoutChange,
    widgetIdToComponentMap,
    setUsersWidgetData,
    widgetsSideBar,
    handleOnBreakpointChange,
    isDashboardLock,
    setIsDashboardWidgetDataLoading,
  } = useWidgets({ localStorageKey: "dashboard" });

  useEffect(() => {
    setIsDashboardWidgetDataLoading(true);
    // getDashboardMain()
    //   .then(getJson)
    //   .then(({ data }) => {
    //     setUsersWidgetData(data.users);
    //     setIsDashboardWidgetDataLoading(false);
    //   });

    //DATA MOCKED TO LOAD PAGE
    setIsDashboardWidgetDataLoading(false);
    setUsersWidgetData(1);
  }, []);

  /* istanbul ignore next */
  const handleBreakpointChange = (newBreakpoint: string): void =>
    handleOnBreakpointChange(newBreakpoint);

  const isDragDropResizeAvailable = (): boolean =>
    !isDashboardLock && !isNodeEnv(NODE_ENVIRONMENTS.TEST);

  return (
    <>
      <>
        <Grid container>
          <Grid item sx={{ pl: 2 }}>
            {widgetsSideBar()}
          </Grid>
        </Grid>
        <Grid container data-testid={"grid-layout-wrapper"}>
          <ResponsiveGridLayout
            onBreakpointChange={handleBreakpointChange}
            isDraggable={isDragDropResizeAvailable()}
            isDroppable={isDragDropResizeAvailable()}
            isResizable={isDragDropResizeAvailable()}
            onLayoutChange={(layout, layouts) =>
              onLayoutChange(layout, layouts)
            }
            layouts={responsiveLayout}
            rowHeight={30}
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          >
            {layout.map((item: Layout) => widgetIdToComponentMap[item.i])}
          </ResponsiveGridLayout>
        </Grid>
      </>
    </>
  );
}

export default Default;
