export const salutations = [
  {
    id: 1,
    code: "mr",
    name: "Herr",
  },
  {
    id: 2,
    code: "mrs",
    name: "Frau",
  },
  {
    id: 3,
    code: "other",
    name: "Divers",
  },
];
