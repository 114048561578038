import AuthGuard from "../../components/guards/AuthGuard";
import DashboardLayout from "../../layouts/dashboard";
import UsersList from "../../components/users/list/UsersList";
import React from "react";

export default {
  path: "/users",
  element: (
    <AuthGuard>
      <DashboardLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "user-management",
      element: <UsersList />,
    },
  ],
};
