/* istanbul ignore next */
export const route = (...params: (string | number | undefined)[]): string => {
  switch (params[0]) {
    //auth
    case "root":
      return `/`;
    case "sign-in":
      return `auth/sign-in`;
    case "forgot-password":
      return `auth/password/forgot`;
    case "reset-password":
      return `auth/password/reset`;
    case "profile.general-information":
      return `profile/general-information`;
    case "profile.notification-settings":
      return `profile/notification-settings`;

    //users
    case "users":
      return `users/user-management`;
    case "users.edit":
      return `users/user-management/edit/${params[1]}`;
    case "users.create":
      return `users/create`;
    case "users.activity":
      return `users/activity`;

    // Clients
    case "clients":
      return `/clients`;
    case "clients.create":
      return `/clients/create`;

    // Area Income Range
    case "areaIncomeRange":
      return `/area-income-range`;
    case "areaIncomeRange.create":
      return `/area-income-range/create`;
    case "areaIncomeRange.edit":
      return `/area-income-range/edit/${params[1]}`;

    // Invoices
    case "invoices":
      return `/invoices`;

    default:
      return `/`;
  }
};
