import {
  FIRST_NAME_MAX_CHARACTERS,
  FIRST_NAME_MIN_CHARACTERS,
  LAST_NAME_MIN_CHARACTERS,
  LAST_NAME_MAX_CHARACTERS,
  MOBILE_MAX_CHARACTERS,
  MOBILE_MIN_CHARACTERS,
} from "../constants";

import sidebar from "./sidebar/en";
import comments from "./comments/en";
import profile from "./profile/en";
import client from "./client/en";
import areaIncomeRange from "./areaIncomeRange/en";
import invoices from "./invoices/en";

export default {
  translation: {
    sidebar,
    comments,
    profile,
    client,
    areaIncomeRange,
    invoices,
    copied: "Copied",
    copyLink: "Copy link",
    edit: "Edit",
    form: {
      emailIsRequired: "E-Mail is required",
      firstNameIsRequired: "First name is required",
      firstNameMaxCharacters: `Max length should be no more then ${FIRST_NAME_MAX_CHARACTERS} characters`,
      firstNameMinCharacters: `Min length should be no less then ${FIRST_NAME_MIN_CHARACTERS} characters`,
      lastNameIsRequired: "Last name is required",
      lastNameMaxCharacters: `Min length should be no less then ${LAST_NAME_MAX_CHARACTERS} characters`,
      lastNameMinCharacters: `Max length should be no more then ${LAST_NAME_MIN_CHARACTERS} characters`,
      mobileNumberIsRequired: "Mobile number is required",
      mobileNumberError: `Mobile number should be more than ${MOBILE_MIN_CHARACTERS} characters`,
      mobileNumberMaxError: `Mobile number should be ${MOBILE_MAX_CHARACTERS} characters or less`,
      mustBeValidEmail: "E-Mail is not valid",
      passwordConfirmationIsRequired: "Confirm password",
      passwordIsRequired: "Password is required",
      passwordsMustMatch: "Password confirmation does not mutch",
      phoneNumberIsRequired: "Phone number is required",
      phoneNumberError: `Phone number should be more than ${MOBILE_MIN_CHARACTERS} characters`,
      phoneNumberMaxError: `Phone number should be ${MOBILE_MAX_CHARACTERS} characters or less`,
      salutationIsRequired: "Salutation form is required",
      roleIsRequired: "Please choose a role",
      passwordMustBeAtLeast: "Password must be at least",
    },
    table: {
      actions: "Actions",
      active: "Active",
      firstName: "First name",
      fullName: "Full name",
      id: "ID",
      inactive: "Inactive",
      lastName: "Last name",
      permissionToRelease: "Release",
      role: "Role",
      rowsPerPage: "Rows per page",
      status: "Status",
      noDataIsAvailable: "No data is available",
      noUsersAreAvailable: "No users are available",
      noAreaIncomeRangeAvailable: "No area income range available",
      noInvoiceAreAvailable: "No invoices are available",
    },
    active: "Active",
    noDataIsAvailable: "No data is available",
    birthday: "Birthday",
    characters: "characters",
    close: "Close",
    contactInformation: "Contact Information",
    email: "Email",
    emailAddress: "Email Address",
    emailIsRequired: "Email is required",
    enterYourEmailToResetPassword: "Please create your new password",
    enterYourEmailToResetYourPassword:
      "Enter your email to reset your password",
    errorSomethingWentWrong: "Something went wrong!",
    firstName: "First Name",
    forgotPassword: "Forgot Password",
    lastName: "Last Name",
    mobileNumber: "Mobile number",
    mustBeValidEmail: "Email is invalid",
    name: "Name",
    comment: "Comment",
    newPassword: "New password",
    password: "Password",
    oldPassword: "Old Password",
    passwordConfirmation: "Password confirmation",
    confirmNewPassword: "Confirm new Password",
    personalInformation: "Personal Information",
    phoneNumber: "Phone number",
    crop: "Crop",
    updateImage: "Update Image",
    profilePictureHintFOrFormat: `For best results, use an image at least 128px by 128px in .jpg format`,
    salutation: {
      label: "Salutation",
      mr: "Mr",
      mrs: "Mrs",
      other: "Other",
    },
    resetPassword: "Reset Password",
    search: "Search",
    sendLink: "Send link",
    signIn: "Sign in",
    signOut: "Sign out",
    somethingWentWrong: "Something went wrong",
    update: "Update",
    users: "Users",
    user: {
      employees: "Employee Overview",
      management: "User Management",
      activity: "Activity",
      ownership: "Company Ownership",
      vacationRequests: "Vacation Requests",
    },
    userWasSuccessfullyUpdated: "User was successfully updated!",
    phoneNumberOrMobileNumberIsRequired:
      "Phone number or Mobile number is required",
    weHaveMissedYou: "We've missed you!",
    welcomeBack: "Welcome back!",
    weSentTheResetPasswordLinkOnYourEmail:
      "We sent the reset password link on your email",
    delete: "Delete",
    cancel: "Cancel",
    deleteConfirmation: "Do you really want to delete?",
    wrongPhoneNumberFormat:
      "Wrong number format. Example: +49152901820 or 0049152901820",
    pages404: {
      pageNotFound: "Page not found",
      pageWasRemoved: "The page you are looking for might have been removed",
      returnToWebsite: "Return to website",
    },
    pages403: {
      noRightsForRoute: "You don't have permissions to view this page",
      contactAdmin: "Contact your administrator for more information",
    },
    home: {
      lockDashboard: "Lock Dashboard",
    },
    widgets: {
      users: "Users",
      total: "Total",
    },
    startTyping: "Start typing to see options",
    resetPasswordEmailWasSend:
      "Reset password link was already sent to your email",
    leaveComment: "Leave a comment...",
    scrollLeft: "Scroll left",
    scrollRight: "Scroll right",
    save: "Save",

    clients: {
      create: "create",
      form: {
        nameIsRequired: "Name is required",
        domainIsRequired: "Domain is required",
        codeIsRequired: "Code is required",
      },
      clientWasSuccessfullyCreated: "Client was created successfully",
    },

    selectedMoreThenOneAnd: "and",
    selectedMoreThenOneMore: "more",
  },
};
