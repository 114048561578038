export default {
  title: "Clients",
  name: "Name",
  code: "Code",
  domain: "Domain",
  module_code: "Module code",
  clientModules: "Client modules",
  moduleUpdated: "Module updated successfully",
  manageModules: "Manage modules",
  createClient: "Create Client",
  more: "code",
  enable: "Status",
  zapierEmail: "Zapier Email",
  modules: "Modules",
  and: "and",
  moreClient: "more",
  integrations: "Integrations",
  logo: "Logo",
};
