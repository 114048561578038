import Stats from "./stats/Stats";
import React, { ReactElement, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Layout } from "react-grid-layout";
import WidgetsIcon from "@mui/icons-material/Widgets";
import map from "lodash/map";
import {
  dashboardLockInitState,
  dashboardWidgetsInitData,
  deleteWidgetIconStyle,
  layoutInitState,
  responsiveLayoutInitSettings,
  responsiveLayoutInitState,
  WIDGET_IDS,
} from "./utils";
import { Checkbox, FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  DashboardLockSection,
  WidgetsList,
  WidgetsSideBarButton,
} from "./styled";
import kebabCase from "lodash/kebabCase";
import { useNavigate } from "react-router-dom";
import { route } from "../../utils/url";

const useWidgets = ({
  localStorageKey,
}: {
  localStorageKey: string;
}): UseWidgetsReturnType => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isWidgetsSidebarActive, setIsWidgetsSidebarActive] = useState(true);
  const [isDashboardLock, setIsDashboardLock] = useState(
    dashboardLockInitState(localStorageKey)
  );

  const [activeBreakpoint, setActiveBreakpoint] =
    useState<BreakpointType | null>("lg");

  const [isDashboardWidgetDataLoading, setIsDashboardWidgetDataLoading] =
    useState(true);

  const [usersWidgetData, setUsersWidgetData] = useState(
    dashboardWidgetsInitData.users
  );

  const [hoveredWidget, setHoveredWidget] = useState("");
  const [layout, setLayout] = useState<Layout[]>(
    layoutInitState(localStorageKey)
  );

  const [responsiveLayout] = useState<Record<string, Layout[]>>(
    responsiveLayoutInitState(localStorageKey)
  );
  /* istanbul ignore next */
  const showCloseIcon = (name: string): ReactElement => {
    if (hoveredWidget === name && !isDashboardLock)
      return (
        <CloseIcon
          sx={deleteWidgetIconStyle}
          onClick={() => handleCloseWidget(name)}
          data-testid="widget-close"
        />
      );
    else return <></>;
  };

  const usersWidget = (): ReactElement => (
    <Stats
      title={WIDGET_IDS.USERS}
      amount={Math.round(usersWidgetData).toString()}
      chip="widgets.total"
      isLoading={isDashboardWidgetDataLoading}
      onClick={() => navigate(route("users"))}
    />
  );

  const widgetIdToComponentMap = {
    users: (
      <div
        key={WIDGET_IDS.USERS}
        onMouseEnter={() => setHoveredWidget(WIDGET_IDS.USERS)}
        onMouseLeave={() => setHoveredWidget("")}
        data-testid="widget-users"
        data-grid={responsiveLayoutInitSettings[activeBreakpoint!].find(
          (el) => el.i === WIDGET_IDS.USERS
        )}
      >
        {usersWidget()}
        {showCloseIcon(WIDGET_IDS.USERS)}
      </div>
    ),
  };

  /* istanbul ignore next */
  const handleOnBreakpointChange = (newBreakpoint: string): void => {
    setActiveBreakpoint(newBreakpoint as BreakpointType);
  };

  const toggleWidget = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: string
  ): void => {
    if (event.target.checked) {
      const layoutInit = responsiveLayoutInitSettings[activeBreakpoint!];
      const itemToAdd = layoutInit.find((el) => el.i === item);
      setLayout((layout) => {
        layout.push(itemToAdd!);
        return [...layout];
      });
    } else {
      setLayout((layout) => [...layout.filter((l: Layout) => l.i !== item)]);
    }
  };

  const onLayoutChange = (
    layout: Layout[],
    responsiveLayout: Record<string, Layout[]>
  ): void => {
    /* istanbul ignore else */
    if (localStorage) {
      const settings = localStorage.getItem(localStorageKey)
        ? JSON.parse(localStorage.getItem(localStorageKey)!)
        : {};
      settings.layouts = responsiveLayout;
      localStorage.setItem(localStorageKey, JSON.stringify(settings));
    } else {
      setLayout(layout);
    }
  };
  /* istanbul ignore next */
  const handleCloseWidget = (name: string): void => {
    setLayout((layout) => layout.filter((l: Layout) => l.i !== name));
  };

  const handleOnWidgetsSidebarButtonClick = (): void => {
    setIsWidgetsSidebarActive((val) => !val);
  };

  const handleLock = (): void => {
    /* istanbul ignore else */
    if (localStorage) {
      const settings = JSON.parse(localStorage.getItem(localStorageKey)!);
      settings.isLocked = !isDashboardLock;
      localStorage.setItem(localStorageKey, JSON.stringify(settings));
    }
    setIsDashboardLock((val) => !val);
  };
  /* istanbul ignore next */
  const widgetsSideBar = (): ReactElement => (
    <>
      <WidgetsList $isHidden={isWidgetsSidebarActive} elevation={2}>
        <div>
          <WidgetsSideBarButton
            $isHidden={isWidgetsSidebarActive}
            startIcon={<WidgetsIcon />}
            variant="outlined"
            onClick={() => handleOnWidgetsSidebarButtonClick()}
            data-testid="widgets-sidebar-button"
          >
            Widgets
          </WidgetsSideBarButton>
        </div>
        {lockWidget()}
        <div>
          <FormGroup sx={{ px: 2 }}>
            {Object.values(WIDGET_IDS).map((item: string) => (
              <FormControlLabel
                key={`id-${item}`}
                control={
                  <Checkbox
                    checked={map(layout, "i").includes(item)}
                    onChange={(e) => toggleWidget(e, item)}
                    data-testid={`${kebabCase(item)}-checkbox`}
                  />
                }
                label={t(`widgets.${item}`)}
              />
            ))}
          </FormGroup>
        </div>
      </WidgetsList>
    </>
  );

  const lockWidget = (): ReactElement => (
    <DashboardLockSection>
      <FormControlLabel
        control={
          <Switch
            checked={isDashboardLock}
            onChange={() => handleLock()}
            data-testid="widgets-sidebar-lock-button"
          />
        }
        label={t("home.lockDashboard")}
      />
    </DashboardLockSection>
  );
  return {
    layout,
    responsiveLayout,
    handleCloseWidget,
    widgetIdToComponentMap,
    setActiveBreakpoint,
    handleOnBreakpointChange,
    widgetsSideBar,
    onLayoutChange,
    setUsersWidgetData,
    isDashboardLock,
    setIsDashboardWidgetDataLoading,
  };
};

export default useWidgets;

export type BreakpointType = "lg" | "md" | "sm" | "xs" | "xxs";

interface UseWidgetsReturnType {
  layout: Layout[];
  setActiveBreakpoint: React.Dispatch<
    React.SetStateAction<BreakpointType | null>
  >;
  responsiveLayout: Record<string, Layout[]>;
  handleCloseWidget: (name: string) => void;
  handleOnBreakpointChange: (newBreakpoint: string) => void;
  widgetsSideBar: () => ReactElement;
  widgetIdToComponentMap: Record<string, ReactElement>;
  onLayoutChange: (layout: Layout[], layouts: Record<string, Layout[]>) => void;
  setUsersWidgetData: React.Dispatch<React.SetStateAction<number>>;
  isDashboardLock: boolean;
  setIsDashboardWidgetDataLoading: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}
