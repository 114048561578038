import { SidebarItemsType } from "./types";
import { Users, Sliders, DollarSign, File } from "react-feather";
import { APP_ENVIRONMENTS, PermissionsRead, UserRoles } from "../../constants";

const pagesSection = [
  {
    href: "root",
    icon: Sliders,
    title: "sidebar.dashboard",
    regexpPath: "/",
    permissions: [PermissionsRead.DASHBOARD],
  },
  {
    href: "clients",
    icon: Users,
    title: "sidebar.clients",
    regexpPath: "/clients(.*)",
    permissions: [PermissionsRead.DASHBOARD],
  },
  {
    href: "areaIncomeRange",
    icon: DollarSign,
    title: "sidebar.areaIncomeRange",
    regexpPath: "/area-income-range(.*)",
    permissions: [PermissionsRead.DASHBOARD],
  },
  {
    href: "",
    icon: Users,
    title: "users",
    regexpPath: "/users(.*)",
    children: [
      {
        href: "users",
        title: "sidebar.userManagement",
        env: [APP_ENVIRONMENTS.DEVELOPMENT, APP_ENVIRONMENTS.LOCAL],
        roles: [UserRoles.ADMIN],
        regexpPath: "/users/user-management(.*)",
        permissions: [PermissionsRead.USER_MANAGEMENT],
      },
    ],
    permissions: [PermissionsRead.USER_MANAGEMENT],
  },
  {
    href: "invoices",
    icon: File,
    title: "sidebar.invoices",
    regexpPath: "/invoices(.*)",
    permissions: [PermissionsRead.INVOICE],
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: "",
    pages: pagesSection,
  },
];

export default navItems;
