import React, { ReactElement, useState, useEffect } from "react";
import Form from "./Form";
import { FormWrapper } from "./styled";
import { Stack } from "@mui/material";
import { Formik } from "formik";
import { handleUpdateUserProfileFormSubmit, getUser } from "./utils";
import { FormMessageInItState } from "../../../../constants";
import AlertCustom from "../../../../ui/formAlert/FormAlert";
import Loader from "../../../Loader";
import { Salutation, User } from "../../../../types/fe/user";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useValidation from "../../../../hooks/useValidation";
import useAppDispatch from "../../../../hooks/useAppDispatch";
import { getSalutations } from "../../../../api/salutations";
import { useFetch } from "../../../../hooks/useFetch";

const UserProfile = (): ReactElement => {
  const { t } = useTranslation();
  const [formMessage, setFormMessage] = useState(FormMessageInItState);
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [error, setError] = useState<any>(null);
  const dispatch = useAppDispatch();

  const {
    first_name,
    last_name,
    salutation_id,
    email,
    phone_number,
    mobile_number,
    profile: { password, password_confirmation, old_password },
  } = useValidation();
  const errorMessageText = t("errorSomethingWentWrong");

  const {
    data: salutations,
    isLoading: isSalutationsLoading,
    isError: isSalutationsError,
    run: runSalutations,
  } = useFetch<Salutation[]>();

  useEffect(() => {
    getUser({
      setIsLoading,
      setUser,
      setError,
      errorMessageText,
    });
    runSalutations(getSalutations());
  }, []);

  if (isSalutationsError || error) return error;
  if (isLoading || isSalutationsLoading) return <Loader />;

  return (
    <React.Fragment>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0}
      >
        <FormWrapper>
          {formMessage.text && <AlertCustom formMessage={formMessage} />}

          {user && salutations && (
            <Formik
              initialValues={user}
              validationSchema={Yup.object().shape(
                {
                  first_name,
                  last_name,
                  salutation_id,
                  email,
                  phone_number,
                  mobile_number,
                  password,
                  password_confirmation,
                  old_password,
                },
                [
                  ["password", "password_confirmation"],
                  ["password", "old_password"],
                  ["password_confirmation", "old_password"],
                ]
              )}
              onSubmit={handleUpdateUserProfileFormSubmit(
                {
                  setFormMessage,
                },
                dispatch
              )}
            >
              {(props): React.ReactElement => (
                <Form salutations={salutations} {...props} />
              )}
            </Formik>
          )}
        </FormWrapper>
      </Stack>
    </React.Fragment>
  );
};

export default UserProfile;
