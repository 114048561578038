import user from "./user";
import profile from "./profile";
import home from "./home";
import auth from "./auth";
import privacy from "./privacy";
import fallback from "./fallback";
import clients from "./clients";
import areaIncomeRange from "./areaIncomeRange";
import invoices from "./invoices";

const routes = [
  home,
  user,
  profile,
  auth,
  privacy,
  fallback,
  clients,
  areaIncomeRange,
  invoices,
];

export default routes;
